import { Box, Flex, Heading, Image } from '@chakra-ui/react';
import ourStory from '../../assets/images/our-story.jpg'
import { useContext, useEffect, useState } from 'react';
import { CountContext } from '../../App';
import robot from '../../assets/vectors/robot.svg'

const WeDoThingsDifferently = () => {
    const { count } = useContext(CountContext)
    const [matches425, setMatches425] = useState(window.matchMedia("(max-width: 425px)").matches)
    useEffect(() => { window.matchMedia("(max-width: 425px)").addEventListener('change', e => setMatches425(e.matches)); }, [matches425])
    return (
        <>
            <Box w={'100%'} h='100%' backgroundImage={`url(${ourStory})`} backgroundSize={'cover'} backgroundPosition={'center center'} cursor={'default'} userSelect={'none'} backgroundAttachment={'absolute'} left='50%' top={'50%'} right={'0'} bottom={'0'} transform={'translate(-50%,-50%)'} position={'absolute'} zIndex={'-1'} transition={'all 1s'} opacity={count === 1 ? 1 : 0} backgroundRepeat={'no-repeat'} >
            </Box>

            <Box zIndex={1000} position={'absolute'} top={'40%'} gap='20px' display={count === 1 ? 'flex' : 'none'} flexDirection={'column'}>
                <Flex justifyContent={'center'}><Image w={['30px', '35px', '40px', '40px', '40px']} h='31px' src={robot} alt='' /></Flex>
                <Box whiteSpace={'nowrap'} overflow={'hidden'} w={'100%'} animation={'type 2s steps(30, end) 1s forwards, weDothingsDifferently 1s linear'}><Heading m='0px' fontFamily={'Condensed Neue Plak SemiBold'} color={'#FFFFFF'} fontWeight={'200'} fontSize={['16px', '16px', '20px', '22px', '22px']} letterSpacing={'0.26rem'}>We Do Things Differently</Heading></Box>
            </Box>
        </>
    )
}
export default WeDoThingsDifferently;