import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  Stack,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import robot from "../assets/vectors/robot.svg";
import Logo from "../components/common/Logo";
import emailjs from "emailjs-com";
import "./contactus.css";

const ContactUs = () => {
  const [matches600, setMatches600] = useState(
    window.matchMedia("(max-width: 600px)").matches
  );
  const [form, setForm] = useState({ name: "", message: "", email: "" });
  const [formError, setFormError] = useState({
    name: "",
    message: "",
    email: "",
  });
  const [loading, setLoading] = useState(false);

  const HtmlForm = useRef();
  const toast = useToast();
  const showSuccessToast = () => {
    toast({
      title: "Submitted Successfully",
      description: "Message have been sent.",
      status: "success",
      duration: 9000,
      isClosable: true,
    });
  };
  const showErrorToast = () => {
    toast({
      title: "Something went wrong, try agin later.",
      duration: 9000,
      isClosable: true,
    });
  };
  const handleChange = (event) => {
    if (event && event?.target) {
      const { value, name } = event?.target;
      if (value === "") setFormError({ ...formError, [name]: true });
      setForm({ ...form, [name]: value });
    }
  };
  const handleTAChange = (event) => {
    handleChange(event);
    event.target.style.height = "auto";
    event.target.style.height = event.target.scrollHeight + "px";
  };
  const submit = () => {
    setLoading(true);
    if (form.name !== "" && form.email !== "" && form.message !== "") {
      emailjs
        .sendForm(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          HtmlForm.current,
          process.env.REACT_APP_PUBLIC_KEY
        )
        .then((result) => {
          showSuccessToast();
        })
        .catch((error) => {
          showErrorToast();
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
      setFormError({
        email: form.email === "" ? true : false,
        message: form.message === "" ? true : false,
        name: form.name === "" ? true : false,
      });
    }
  };
  useEffect(() => {
    window
      .matchMedia("(max-width: 600px)")
      .addEventListener("change", (e) => setMatches600(e.matches));
  }, [matches600]);

  document.title = "Contact Us";
  return (
    <>
      <Box
        w={["450%", "350%", "240%", "200%", "200%"]}
        h="100%"
        animation=" 3s ease-out 0s 1 fade"
        bg="linear-gradient(to bottom right, #000000 0%, #1C4A4C 14%, #49C2C7 30% , #000000 44%) 99.9%, linear-gradient(to bottom left, #000000 0%, #1C4A4C 14%, #49C2C7 30%, #000000 44%) 0%;"
        backgroundSize={"50% 100%"}
        left="50%"
        top={"50%"}
        right={"0"}
        bottom={"0"}
        transform={"translate(-50%,-50%)"}
        position={"fixed"}
        zIndex={"-1"}
        backgroundRepeat={"no-repeat"}
      />

      <Box
        position={"fixed"}
        w="100%"
        left={"0%"}
        top={"-10%"}
        animation=" 2s ease-in fade"
      >
        <Text
          transition={"all 0.5s"}
          textAlign={"center"}
          mt="170px"
          fontWeight={"400"}
          opacity={"1"}
          fontFamily={"Extended Thin Neue Plak"}
          letterSpacing={"0.1rem"}
          color={"white"}
          fontSize={["12px", "14px", "18px", "20px", "20px"]}
          bg="transparent"
        >
          Contact Us
        </Text>
        <Flex
          mr={"10px"}
          justifyContent={"center"}
          mt={["0px", "5px", "25px", "25px", "25px"]}
          alignItems={"center"}
          id="logo"
        >
          <Logo wSize={400} hSize={"62px"} setPosition={true} />
          <h1 className="sr-only">PanCode</h1>
        </Flex>
      </Box>

      <Flex
        position={"fixed"}
        top={["-10%", "0%", "0%", "0%", "0%"]}
        left={"50%"}
        transform={"translate(-50%,25%)"}
        animation={"2.2s ease-in 0s 1 fade"}
        gap={"50px"}
        flexDirection={"column"}
        id="contactus"
        w="fit-content"
        h="100vh"
      >
        <Flex
          mt="80px"
          flexDirection={"column"}
          gap={"40px"}
          w="100%"
          p={"25px"}
        >
          <Flex
            alignItems={"center"}
            justifyContent={"start"}
            ml={"25px"}
            w="100%"
            gap={"20px"}
            h="100%"
            position="relative"
          >
            <Image w="20px" src={robot} alt="" />
            <Box
              whiteSpace={"nowrap"}
              h={"25px"}
              overflow={"hidden"}
              w="200px"
              animation={
                "type 2s steps(30, end) 1s forwards, contactus 1s linear"
              }
            >
              <Text
                lineHeight={"23px"}
                color="white"
                textAlign={"center"}
                fontFamily="Condensed Neue Plak SemiBold"
                fontSize={"25px"}
              >
                We'd Love to Hear From You
              </Text>
            </Box>
          </Flex>
          <form
            ref={HtmlForm}
            style={{ maxWidth: "80%", marginLeft: "auto", marginRight: "auto" }}
          >
            <Stack
              spacing={"10"}
              w={"300px"}
              maxWidth={"100%"}
              marginLeft={"auto"}
              marginRight={"auto"}
            >
              <FormControl isInvalid={formError.name}>
                <FormLabel
                  fontSize={"14px"}
                  fontFamily={"Extended Light Neue Plak"}
                  m="0"
                  color={"white"}
                >
                  Name
                </FormLabel>
                <Input
                  onChange={handleChange}
                  color={"white"}
                  p="0px"
                  h="20px"
                  _focusVisible={{ borderBottom: "4px solid white" }}
                  m="0"
                  borderBottom={"1px solid white"}
                  borderTop={"none !important"}
                  borderLeft={"none !important"}
                  borderRight={"none !important"}
                  borderRadius={"0px !important"}
                  boxShadow={"none !important"}
                  type="text"
                  name="name"
                  maxWidth={"100%"}
                  height={"24px"}
                  lineHeight={"24px"}
                />
                <FormErrorMessage>
                  {form.name === "" ? "name cannot be empty" : ""}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={formError.email}>
                <FormLabel
                  fontSize={"14px"}
                  fontFamily={"Extended Light Neue Plak"}
                  m="0"
                  color={"white"}
                >
                  Email
                </FormLabel>
                <Input
                  onChange={handleChange}
                  rows={2}
                  color={"white"}
                  p="0px"
                  h="24px"
                  _focusVisible={{ borderBottom: "4px solid white" }}
                  m="0"
                  borderBottom={"1px solid white"}
                  borderTop={"none"}
                  borderLeft={"none"}
                  borderRight={"none"}
                  borderRadius={"0px"}
                  boxShadow={"none !important"}
                  type="text"
                  name="email"
                  maxWidth={"100%"}
                  height={"24px"}
                  lineHeight={"24px"}
                />
                <FormErrorMessage>
                  {form.email === "" ? "email cannot be empty" : ""}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={formError.message}>
                <FormLabel
                  fontSize={"14px"}
                  fontFamily={"Extended Light Neue Plak"}
                  color={"white"}
                  m="0"
                >
                  Message
                </FormLabel>
                <Textarea
                  onChange={handleTAChange}
                  resize={"none"}
                  color={"white"}
                  height={`auto !important`}
                  lineHeight={"24px"}
                  p="0px"
                  _focusVisible={{ borderBottom: "4px solid white" }}
                  m="0"
                  borderBottom={"1px solid white"}
                  borderTop={"none"}
                  borderLeft={"none"}
                  borderRight={"none"}
                  borderRadius={"0px"}
                  boxShadow={"none !important"}
                  name="message"
                  maxWidth={"100%"}
                  maxHeight={"78px"}
                  padding={"0"}
                  className="contact-textarea"
                />
                <FormErrorMessage>
                  {form.message === "" ? "message cannot be empty" : ""}
                </FormErrorMessage>
              </FormControl>

              <Button
                onClick={submit}
                isLoading={loading}
                alignSelf={"center"}
                w="fit-content"
                borderRadius={"0px"}
                borderBottomRightRadius={"15px"}
                color={"black"}
                bg="white"
                fontFamily={"Extended Bold Neue Plak"}
              >
                <Text m="0" paddingInline={"10px"}>
                  Send
                </Text>
              </Button>
            </Stack>
          </form>
        </Flex>
      </Flex>
    </>
  );
};
export default ContactUs;
