export const Keyboard = (setCount, count) => {
    document.addEventListener('keyup', (event) => {
        var name = event.key;
        if (name === '1') setCount(0)
        if (name === '2') setCount(1)
        if (name === '3') setCount(2)
        if (name === '4') setCount(3)
        if (name === '5') setCount(4)
        // if (name === 'ArrowUp') setCount(count - 1)
        // if (name === 'ArrowDown') setCount(count + 1)
    }, false);
}