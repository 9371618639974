import { gsap } from "gsap";
const Cursor = () => {
    let cursor = document.getElementById('ball');
    let cursorScale = document.querySelectorAll('.cursor-hover');
    if ('ontouchstart' in window) {
         document.getElementById('ball').style.display = 'none'
        return;
    }
    else {
        gsap.set(".ball", { xPercent: -50, yPercent: -50 });
        let xTo = gsap.quickTo(".ball", "x", { duration: 0.5, ease: "power2" }),
            yTo = gsap.quickTo(".ball", "y", { duration: 0.5, ease: "power2" });

        window.addEventListener("mousemove", e => {
            xTo(e.clientX);
            yTo(e.clientY);
        });

        if (cursor) {

            cursorScale.forEach(link => {
                link.addEventListener('mousemove', () => {
                    cursor.style.backgroundColor = 'transparent'
                });

                link.addEventListener('mouseleave', () => {
                    cursor.style.backgroundColor = 'lightgray'
                });
            })
        }
        // if (event.x <= 75 && event.y <= 75) {
        //     gsap.set(".moon", { xPercent: -70, yPercent:-70 });

        //     let xMTo = gsap.quickTo(".moon", "x", { duration: 0.6, ease: "power3" }),
        //         yMTo = gsap.quickTo(".moon", "y", { duration: 0.6, ease: "power3" });

        //     window.addEventListener("mousemove", e => {
        //         xMTo(e.clientX);
        //         yMTo(e.clientY);
        //     });
        // }


    }
}
export default Cursor;