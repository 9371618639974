import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { Fullscreen } from "../util/Fullscreen";
import Logo from "../components/common/Logo";
import { useNavigate } from "react-router-dom";

const Error404 = () => {
  const navigate = useNavigate();

  return (
    <>
      <Box onDoubleClick={Fullscreen} position={"relative"} overflow={"hidden"}>
        <Box id="home" w="100%" h="100vh" position={"fixed"}>
          <Flex
            h="100%"
            w="100%"
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box
              w={["500%", "400%", "240%", "200%", "200%"]}
              h="100%"
              animation=" 3s ease-out 0s 1 fade"
              bg="linear-gradient(to bottom right, #000000 0%, #1C4A4C 14%, #49C2C7 30% , #000000 44%) 99.9%, linear-gradient(to bottom left, #000000 0%, #1C4A4C 14%, #49C2C7 30%, #000000 44%) 0%;"
              backgroundSize={"50% 100%"}
              left="50%"
              top={"50%"}
              right={"0"}
              bottom={"0"}
              transform={"translate(-50%,-50%)"}
              opacity={1}
              position={"absolute"}
              zIndex={"-1"}
              backgroundRepeat={"no-repeat"}
            ></Box>
            <Box
              w="100%"
              left={"50%"}
              animation=" 2s ease-in fade"
              transition={"all 0.5s"}
            >
              <Text
                id="welcome"
                transition={"all 0.5s"}
                textAlign={"center"}
                mt={["100px", "100px", "100px", "100px", "100px"]}
                fontWeight={"400"}
                opacity={"1"}
                fontFamily={"Extended Thin Neue Plak"}
                letterSpacing={"0.1rem"}
                fontSize={["14px", "14px", "25px", "25px", "25px"]}
                color={"white"}
                bg="transparent"
              >
                Error 404
              </Text>
              <Flex
                mr={"10px"}
                transition={"all 0.5s"}
                justifyContent={"center"}
                mt={["0px", "5px", "10px", "10px", "10px"]}
                alignItems={"center"}
                id="logo"
              >
                <Logo wSize={400} setPosition={true} />
                <h1 className="sr-only">PanCode</h1>
              </Flex>

              <Text
                id="welcome"
                transition={"all 0.5s"}
                textAlign={"center"}
                mt={["100px", "100px", "100px", "100px", "100px"]}
                fontWeight={"400"}
                opacity={"1"}
                fontFamily={"Extended Thin Neue Plak"}
                letterSpacing={"0.1rem"}
                fontSize={"35px"}
                color={"white"}
                bg="transparent"
              >
                Page Not Found!
              </Text>
            </Box>

            <Flex
              zIndex={"1"}
              position="absolute"
              justifyContent={"center"}
              bottom={"100px"}
              animation=" 4s ease-in fade"
              transition={"all 0.5s"}
              transform={"translateY(0px)"}
            >
              <Box w={["80%", "70%", "65%", "65%", "62%"]}>
                <Flex
                  gap={"10px"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  mt="20px"
                >
                  <Button
                    onClick={() => navigate("/")}
                    cursor={"pointer"}
                    transition={"all 0.5s"}
                    _hover={{ bg: "transparent", color: "#49C2C7" }}
                    p="10px 32px"
                    bg="#49C2C7"
                    fontSize={"15px"}
                    fontWeight={"bold"}
                    fontFamily={"Neue Plak"}
                    color="#272727"
                    border="1px solid #49C2C7"
                    borderRadius={"25px"}
                    padding={"0.5em 4em"}
                  >
                    Home Page
                  </Button>
                </Flex>
              </Box>
            </Flex>
          </Flex>
        </Box>
      </Box>
    </>
  );
};
export default Error404;
