import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { CountContext } from "../../../App";
import laptop from "../../../assets/images/laptop-mock-up.png";
import robot from "../../../assets/vectors/robot.svg";
import IconLeft from "../../common/IconLeft";
import IconRight from "../../common/IconRight";

const PartnersCard = ({ logo, link, content, partners, setPartners, alt }) => {
  const { count } = useContext(CountContext);
  const [matches480, setMatches480] = useState(
    window.matchMedia("(max-width: 635px)").matches
  );
  useEffect(() => {
    window
      .matchMedia("(max-width: 635px)")
      .addEventListener("change", (e) => setMatches480(e.matches));
  }, [matches480]);
  return (
    <Box
      position={"relative"}
      w="fit-content"
      h="fit-content"
      animation={"2.2s ease-in 0s 1 fade"}
      display={count === 4 ? "block" : "none"}
    >
      {!matches480 && (
        <>
          <Box
            onClick={
              partners >= 1
                ? () => {
                    setPartners(partners - 1);
                  }
                : () => null
            }
            cursor={"pointer"}
            zIndex={"10"}
            position={"absolute"}
            top={["-100%", "0%", "0%", "10%", "20%"]}
            left={"0%"}
          >
            <IconLeft width={"35"} opacity={partners === 0 ? "0.22" : "0.66"} />
          </Box>

          <Box
            onClick={
              partners <= 4
                ? () => {
                    setPartners(partners + 1);
                  }
                : () => null
            }
            cursor={"pointer"}
            zIndex={"10"}
            position={"absolute"}
            top={["-100%", "0%", "0%", "10%", "20%"]}
            right={"0%"}
          >
            <IconRight
              width={"35"}
              opacity={partners === 5 ? "0.22" : "0.66"}
            />
          </Box>

          <Box
            w={["400px", "600px", "600px", "800px", "900px"]}
            h={["200px", "400px", "400px", "500px", "500px"]}
            position={"relative"}
            left="50%"
            top={["-100%", "0%", "5%", "15%", "25%"]}
            right={"0"}
            bottom={"0"}
            transform={"translate(-50%,-50%)"}
          >
            <Image
              w="100%"
              h="100%"
              position={"absolute"}
              src={laptop}
              alt=""
            />
          </Box>
          <Flex
            w="80%"
            h="90%"
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
            background={"rgba(14, 36, 37, 0.4)"}
            left="50%"
            top={["-100%", "-5%", "0%", "10%", "20%"]}
            right={"0"}
            bottom={"0"}
            transform={"translate(-50%,-50%)"}
            zIndex={"-1"}
            position={"absolute"}
            backdropFilter={"blur(10px)"}
          />

          <Flex
            w={"80%"}
            h="90%"
            justifyContent={"space-between"}
            gap={"20px"}
            alignItems={"center"}
            flexDirection={"column"}
            position={"absolute"}
            top={["-100%", "-10%", "0%", "10%", "20%"]}
            left={"50%"}
            transform={"translate(-50%,-50%)"}
            zIndex={"1"}
          >
            <Flex
              mt="100px"
              alignItems={"center"}
              flexDirection={"column"}
              justifyContent={"center"}
              gap={"30px"}
              w="150px"
              h="40px"
            >
              <Image w="100%" h="100%" src={logo} alt={alt} />
              <Button
                onClick={() => window.open(link, "_blank")}
                fontSize={"12px"}
                w="fit-content"
                cursor={"pointer"}
                paddingInline={"12px"}
                paddingBlock={"5px"}
                color={"#141414"}
                fontWeight={"400"}
                borderRadius={"0px"}
                borderBottomRightRadius={"15px"}
                fontFamily={"Extended Bold Neue Plak"}
                bg="white"
              >
                GO
              </Button>
            </Flex>
            <Flex
              mb="40px"
              justifyContent={"center"}
              gap={"10px"}
              alignItems={"center"}
              flexDirection={"column"}
            >
              <Image w="27px" h="30px" src={robot} alt="" />
              <Text
                paddingInline={"20px"}
                color={"white"}
                fontFamily={"Extended Regular Neue Plak"}
                fontWeight={"400"}
                textAlign={"center"}
                fontSize={"15px"}
              >
                {content}
              </Text>
            </Flex>
          </Flex>
        </>
      )}
      <Box
        w="100%"
        position={"Fixed"}
        top={matches480 ? "20%" : "13%"}
        left={"50%"}
        transform={"translate(-50%,-50%)"}
      >
        <Text
          m="0"
          textAlign={"center"}
          fontWeight={"400"}
          fontFamily={"Extended Light Neue Plak"}
          fontSize={"32px"}
          color={"white"}
          paddingBottom={"1rem"}
        >
          Loved By
        </Text>
      </Box>

      {matches480 && (
        <>
          <Box position={"Fixed"} top={"60%"} left={"44%"} zIndex={10}>
            <Button
              onClick={() => window.open(link, "_blank")}
              fontSize={"14px"}
              h={"fit-content"}
              w="fit-content"
              cursor={"pointer"}
              paddingInline={"12px"}
              paddingBlock={"5px"}
              color={"#141414"}
              fontWeight={"400"}
              borderRadius={"0px"}
              borderBottomRightRadius={"15px"}
              fontFamily={"Extended Bold Neue Plak"}
              bg="white"
            >
              GO
            </Button>
          </Box>

          <Flex
            mb="100px"
            justifyContent={"center"}
            gap={"10px"}
            alignItems={"center"}
            flexDirection={"column"}
          >
            <Image w="27px" h="30px" src={robot} alt="" />
            <Text
              paddingInline={"20px"}
              color={"white"}
              fontFamily={"Extended Regular Neue Plak"}
              fontWeight={"400"}
              textAlign={"center"}
              fontSize={"12px"}
            >
              {content}
            </Text>
          </Flex>
          <Box
            cursor={"pointer"}
            zIndex={"10"}
            position={"fixed"}
            top={["57%", "0%", "0%", "10%", "20%"]}
            left={"5%"}
          >
            <IconLeft width={"25"} opacity={"0.22"} />
          </Box>
          <Box
            cursor={"pointer"}
            zIndex={"10"}
            position={"fixed"}
            top={["57%", "0%", "0%", "10%", "20%"]}
            right={"5%"}
          >
            <IconRight width={"25"} opacity={"0.22"} />
          </Box>
        </>
      )}
    </Box>
  );
};
export default PartnersCard;
