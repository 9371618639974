import { useEffect, useState } from "react";
import "./load-counter.css";

const LoadCounter = ({
  value = 0,
  before = "",
  after = "",
  updateInterval = 5,
}) => {
  const [currentValue, setCurrentValue] = useState(value);

  let timeout;
  useEffect(() => {
    clearTimeout(timeout);
    if (value > currentValue) {
      timeout = setTimeout(
        () => setCurrentValue((prev) => prev + 1),
        updateInterval
      );
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [value, currentValue]);

  return (
    <div className="load-counter-container">
      <span
        className="load-counter"
        style={{ marginLeft: `calc(${value} * var(--logo-width) / 100)` }}
      >
        {before}
        {currentValue}
        {after}
      </span>
    </div>
  );
};
export default LoadCounter;
