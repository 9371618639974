import { Box, Flex } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { CountContext } from "../../../App";
import captainCar from "../../../assets/vectors/partners/captain-car.svg";
import facedeer from "../../../assets/vectors/partners/facedeer.svg";
import hungryOmar from "../../../assets/vectors/partners/hungry-omar.svg";
import legalClinic from "../../../assets/vectors/partners/legal-clinic.svg";
import sekkara from "../../../assets/vectors/partners/sekkara.svg";
import us from "../../../assets/vectors/partners/us.svg";
import { EffectCoverflow, Keyboard, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  CaptainCar,
  Facedeer,
  HungryOmar,
  LegalClinic,
  Sekkara,
  Us,
} from "./Logos";
import PartnersCard from "./PartnersCard";

const partnersData = [
  {
    id: 0,
    logo: us,
    alt: "US Incorporated",
    content: `Operates globally in manufacturing, OEM partnerships, distribution, and logistics. They manufacture and distribute commercial kitchen equipment, scientific and medical instruments, solar and battery, and pharmaceuticals. They have expansion projects in Istanbul, The Republic of Congo, and Nigeria.`,
    link: "https://us-incorporated.com/",
  },
  {
    id: 1,
    logo: legalClinic,
    alt: "Virtual Legal Platform",
    content: `The United Nations Development Programmer (UNDP) create (Your Legal Advisor) online platform. The platform provides free legal consultations, legal awareness, and assistance to individuals, with the aim of enabling them to access legal information and understanding their rights and the procedures for obtaining them, while improving the efficiency of the Bar Association's legal intervention and ensuring its sustainability.`,
    link: "https://virtuallegalplatform.com/",
  },
  {
    id: 2,
    logo: sekkara,
    alt: "Sekkara",
    content: `Is a discount app "Get ready to save big with our discount app, offering unbeatable deals on all your favorite products and services for everyone, regardless of age or gender!"`,
    link: "https://www.sekkara.com/",
  },
  {
    id: 3,
    logo: captainCar,
    alt: "Captain Car",
    content: `Taxi driving applications have revolutionized the transportation industry by providing a more convenient and efficient way for passengers to get around.`,
    link: "https://play.google.com/store/apps/details?id=com.elkood.elcaptainusers&hl=en&gl=US",
  },
  {
    id: 4,
    logo: facedeer,
    alt: "Facedeer",
    content: `Online platform that allows businesses to sell products or services to customers over the internet include features such as a product catalog, shopping cart, checkout, and payment processing system.`,
    link: "https://facedeer.com",
  },
  {
    id: 5,
    logo: hungryOmar,
    alt: "The Hungry Omar",
    content: `Food blogger website serves people who crave unique flavors, experiences, and ideas while creating opportunities for chefs to showcase their creativity.`,
    link: "https://thehungryomar.com",
  },
];

const OurPartners = () => {
  const [swiperRef, setSwiperRef] = useState();
  const { count } = useContext(CountContext);
  const [partners, setPartners] = useState(0);
  const [matches635, setMatches635] = useState(
    window.matchMedia("(max-width: 635px)").matches
  );
  useEffect(() => {
    window
      .matchMedia("(max-width: 635px)")
      .addEventListener("change", (e) => setMatches635(e.matches));
  }, [matches635]);

  const navigatePartners = (e) => {
    // e.preventDefault();
    if (e.code === "ArrowLeft") {
      setPartners((prev) => (prev === 0 ? 0 : prev - 1));
    }
    if (e.code === "ArrowRight") {
      setPartners((prev) =>
        prev === partnersData.length - 1 ? prev : prev + 1
      );
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", navigatePartners);

    return () => {
      window.removeEventListener("keydown", navigatePartners);
    };
  }, []);

  return (
    <>
      <Box
        animation={"2.2s ease-in 0s 1 fade"}
        display={count === 4 ? "block" : "none"}
        w={["450%", "350%", "240%", "200%", "200%"]}
        h="100%"
        bg="linear-gradient(to bottom right, #000000 0%, #1C4A4C 14%, #49C2C7 30% , #000000 44%) 99.9%, linear-gradient(to bottom left, #000000 0%, #1C4A4C 14%, #49C2C7 30%, #000000 44%) 0%;"
        backgroundSize={"50% 100%"}
        left="50%"
        top={"50%"}
        right={"0"}
        bottom={"0"}
        transform={"translate(-50%,-50%)"}
        opacity={count === 4 ? 1 : 0}
        position={"absolute"}
        zIndex={"-1"}
        backgroundRepeat={"no-repeat"}
      />

      <PartnersCard
        partners={partners}
        setPartners={setPartners}
        {...partnersData[partners]}
      />

      <Flex
        zIndex={"10"}
        animation={"2.2s ease-in 0s 1 fade"}
        display={count === 4 ? "flex" : "none"}
        w={"100%"}
        position={"absolute"}
        top={matches635 ? "48%" : "88%"}
        gap="50px"
        alignItems={"center"}
        justifyContent={"center"}
      >
        {matches635 ? (
          <Swiper
            initialSlide={"2"}
            onSwiper={setSwiperRef}
            grabCursor={true}
            coverflowEffect={{
              slideShadows: true,
              depth: 100,
            }}
            onTransitionEnd={() =>
              setPartners(
                swiperRef?.realIndex !== undefined ? swiperRef?.realIndex : 1
              )
            }
            keyboard={{ enabled: true }}
            modules={[Keyboard, EffectCoverflow, Navigation]}
            className="mySwiper"
            slidesPerView={[5]}
            spaceBetween={80}
            style={{ position: "relative", left: "-20px", zIndex: 100 }}
          >
            <SwiperSlide> </SwiperSlide>
            <SwiperSlide> </SwiperSlide>
            <SwiperSlide style={{ boxShadow: "none" }}>
              <Us
                onClick={() => setPartners(0)}
                color={
                  partners === 0 ? "white" : matches635 ? "lightgray" : "gray"
                }
                left={partners === 0 ? "-30px" : "0px"}
                width={partners === 0 ? "100px" : matches635 ? "50px" : "100%"}
              />
            </SwiperSlide>
            <SwiperSlide style={{ boxShadow: "none" }}>
              <LegalClinic
                onClick={() => setPartners(1)}
                color={
                  partners === 1 ? "white" : matches635 ? "lightgray" : "gray"
                }
                left={
                  partners === 1
                    ? "-35px"
                    : partners === 2 || partners === 0
                    ? "-20px"
                    : ""
                }
                width={partners === 1 ? "120px" : matches635 ? "80px" : "100%"}
              />
            </SwiperSlide>
            <SwiperSlide style={{ boxShadow: "none" }}>
              <Sekkara
                onClick={() => setPartners(2)}
                color={
                  partners === 2 ? "white" : matches635 ? "lightgray" : "gray"
                }
                left={partners === 2 ? "-20px" : partners === 3 ? "-10px" : ""}
                width={partners === 2 ? "100px" : matches635 ? "70px" : "100%"}
              />
            </SwiperSlide>
            <SwiperSlide style={{ boxShadow: "none" }}>
              <CaptainCar
                onClick={() => setPartners(3)}
                color={
                  partners === 3 ? "white" : matches635 ? "lightgray" : "gray"
                }
                left={partners === 3 ? "-20px" : partners === 4 ? "-10px" : ""}
                width={partners === 3 ? "100px" : matches635 ? "80px" : "100%"}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Facedeer
                onClick={() => setPartners(4)}
                color={
                  partners === 4 ? "white" : matches635 ? "lightgray" : "gray"
                }
                left={partners === 4 ? "-20px" : ""}
                width={partners === 4 ? "100px" : matches635 ? "60px" : "100%"}
              />
            </SwiperSlide>
            <SwiperSlide style={{ boxShadow: "none" }}>
              <HungryOmar
                onClick={() => setPartners(5)}
                color={
                  partners === 5 ? "white" : matches635 ? "lightgray" : "gray"
                }
                left={partners === 5 ? "-25px" : "-16px"}
                width={partners === 5 ? "100px" : matches635 ? "80px" : "100%"}
                height={partners === 5 ? "40" : matches635 ? "35" : "100%"}
              />
            </SwiperSlide>
            <SwiperSlide> </SwiperSlide>
            <SwiperSlide> </SwiperSlide>
          </Swiper>
        ) : (
          <>
            <Box
              transition={"width 0.5s ease"}
              width={partners === 0 ? "120px" : "100px"}
            >
              <Us
                onClick={() => setPartners(0)}
                color={partners === 0 ? "white" : "gray"}
                width={partners === 0 ? "120px" : "100%"}
              />{" "}
            </Box>
            <Box
              transition={"width 0.5s ease"}
              width={partners === 1 ? "120px" : "100px"}
            >
              {" "}
              <LegalClinic
                onClick={() => setPartners(1)}
                color={partners === 1 ? "white" : "gray"}
                width={partners === 1 ? "120px" : "100%"}
              />
            </Box>
            <Box
              transition={"width 0.5s ease"}
              width={partners === 2 ? "120px" : "100px"}
            >
              <Sekkara
                onClick={() => setPartners(2)}
                color={partners === 2 ? "white" : "gray"}
                width={partners === 2 ? "120px" : "100%"}
              />{" "}
            </Box>
            <Box
              transition={"width 0.5s ease"}
              width={partners === 3 ? "120px" : "100px"}
            >
              {" "}
              <CaptainCar
                onClick={() => setPartners(3)}
                color={partners === 3 ? "white" : "gray"}
                width={partners === 3 ? "120px" : "100%"}
              />
            </Box>
            <Box
              transition={"width 0.5s ease"}
              width={partners === 4 ? "120px" : "100px"}
            >
              {" "}
              <Facedeer
                onClick={() => setPartners(4)}
                color={partners === 4 ? "white" : "gray"}
                width={partners === 4 ? "120px" : "100%"}
              />
            </Box>
            <Box
              transition={"width 0.5s ease"}
              width={partners === 5 ? "120px" : "100px"}
            >
              {" "}
              <HungryOmar
                onClick={() => setPartners(5)}
                color={partners === 5 ? "white" : "gray"}
                width={partners === 5 ? "120px" : "100%"}
                height={partners === 5 ? "40" : "35"}
              />
            </Box>
          </>
        )}
      </Flex>
    </>
  );
};
export default OurPartners;
