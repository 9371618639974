import "./percentage.css";

const Percentage = ({ percentage }) => {
  return (
    <div className="percentage">
      <div
        className="percentage-fill"
        style={{ transform: `scaleX(${percentage / 100})` }}
      ></div>
    </div>
  );
};
export default Percentage;
