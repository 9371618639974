import {
  Box,
  Flex,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import menu from "../../assets/vectors/menu.svg";
import "../../assets/styles/header.css";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CountContext } from "../../App";
import Footer from "./Footer";
const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { count, setCount } = useContext(CountContext);
  const navigate = useNavigate();
  const location = useLocation();

  const ContactUs = () => {
    document.body.style.transition = "opacity 2s ease";
    document.body.style.opacity = "0";
    setTimeout(() => {
      onClose();
    }, 1700);
    setTimeout(() => {
      navigate("/contact-us");
      setCount(0);
      document.body.style.opacity = "1";
    }, 2000);
  };
  return (
    <>
      <Flex
        id="header"
        ml={["25px", "30px", "40px", "40px", "40px"]}
        display={location.pathname === "/" ? "flex" : "none"}
        position={"absolute"}
        top="38px"
        left="0"
        zIndex={10}
        fontWeight={"400"}
        fontFamily={"Inter"}
      >
        <Text
          color={"white"}
          lineHeight={"15px"}
          position={"relative"}
          left={"5px"}
          fontSize={"20px"}
        >
          {`${count + 1}`} &nbsp;
        </Text>
        <Text fontSize={"12px"} color={count === 4 ? "white" : "#5C5C5C"}>
          | 5
        </Text>
      </Flex>
      {/* <Image
        className="cursor-hover"
        onClick={onOpen}
        cursor={"pointer"}
        src={menu}
        position={"absolute"}
        transition={"all 0.5s"}
        filter={isOpen ? "blur(10px)" : ""}
        zIndex={10}
        right="0"
        pr={["25px", "30px", "40px", "40px", "40px"]}
        top={"30px"}
        alt=""
      /> */}

      <Flex
        flexDirection={"column"}
        gap={"7px"}
        dir="rtl"
        className="cursor-hover"
        onClick={onOpen}
        cursor={"pointer"}
        position={"absolute"}
        transition={"all 0.5s"}
        filter={isOpen ? "blur(10px)" : ""}
        zIndex={10}
        right="0"
        pr={["25px", "30px", "40px", "40px", "40px"]}
        top={"35px"}
      >
        <Box
          w={["25px","40px","40px","40px","40px"]}
          borderRadius={"5px"}
          bg="white"
          h={["1px", "2px", "4px", "4px", "4px"]}
        ></Box>
        <Box
          w={["17px","30px","30px","30px","30px"]}
          borderRadius={"5px"}
          bg="white"
          h={["1px", "2px", "4px", "4px", "4px"]}
        ></Box>
        <Box
          w={["10px","20px","20px","20px","20px"]}
          borderRadius={"5px"}
          bg="white"
          h={["1px", "2px", "4px", "4px", "4px"]}
        ></Box>
      </Flex>

      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        motionPreset="slideInBottom"
      >
        <ModalOverlay backdropFilter="blur(10px) " />
        <ModalCloseButton
          bg="none"
          border={"none"}
          position={"absolute"}
          zIndex={9999}
          top={"27px"}
          cursor={"pointer"}
          right={["25px", "34px", "34px", "34px", "38px"]}
          fontSize={"30px"}
          color={"white"}
        />
        <ModalContent
          boxShadow={"none"}
          bg="none"
          color={"white"}
          fontFamily={"Condensed Neue Plak"}
          fontSize={["50px", "60px", "70px", "70px", "70px"]}
          fontWeight={"600"}
        >
          <Text
            cursor={"pointer"}
            m="10"
            onClick={() => {
              navigate("/");
              onClose();
              setCount(0);
            }}
            textAlign={"center"}
          >
            Home
          </Text>
          <Text
            cursor={"pointer"}
            m="10"
            onClick={() => {
              setTimeout(() => {
                navigate("/services");
                onClose();
                setCount(0);
              }, 500);
            }}
            textAlign={"center"}
          >
            Services
          </Text>
          <Text
            cursor={"pointer"}
            m="10"
            onClick={() =>
              location.pathname === "/contact-us" ? onClose() : ContactUs()
            }
            textAlign={"center"}
          >
            Contact
          </Text>
        </ModalContent>
      </Modal>
      {isOpen && <Footer />}
    </>
  );
};
export default Header;
