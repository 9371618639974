export const ParallaxStars = () => {
    let Stars = document.getElementById('bg-stars')
    if ('ontouchstart' in window) {
        document.body?.addEventListener('touchmove', e => {

            var cvalueX = (e.changedTouches[0].clientX * 1 / 20);

            if (Stars) {
                Stars.style.transform = `translateX(${cvalueX}px)`;
            }

        })
    }
    else {
        document.body?.addEventListener('mousemove', e => {
            var cvalueX = (e.pageX * 1 / 90);
            var cvalueY = (e.pageY * 1 / 90);
            if (Stars) {
                    Stars.style.transform = `translate3d(${cvalueX}px,${cvalueY}px,0)`;
            }

        })
    }
}