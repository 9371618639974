import { useEffect, useRef, useState } from "react";
import Logo from "../../common/Logo";
import Percentage from "../Percentage";
import LoadCounter from "../LoadCounter";

//ai systems
import Ai from "../../../assets/images/services/ai systems/ai.png";
import Systems from "../../../assets/images/services/ai systems/systems.png";

//mobile apps
import apps from "../../../assets/images/services/mobile apps/apps.png";
import mobile from "../../../assets/images/services/mobile apps/mobile.png";

//penetration testing
import penetration from "../../../assets/images/services/penetration testing/penetration.png";
import testing from "../../../assets/images/services/penetration testing/testing.png";

//software development
import development from "../../../assets/images/services/software development/development.png";
import software from "../../../assets/images/services/software development/software.png";

//system analysis & erp
import analysis from "../../../assets/images/services/system analysis & erp/analysis.png";
import erp from "../../../assets/images/services/system analysis & erp/erp.png";
import system from "../../../assets/images/services/system analysis & erp/system.png";

//ui ux
import ui from "../../../assets/images/services/ui ux/ui.png";
import ux from "../../../assets/images/services/ui ux/ux.png";

const Loader = ({ loading, setLoading }) => {
  const [percentage, setPercentage] = useState(0);
  const [fontsPercentage, setFontsPercentage] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState(0);

  useEffect(() => {
    if (percentage < 100) {
      const newPercentage = fontsPercentage + imagesLoaded + 4;
      if (newPercentage >= 100) {
        setPercentage(100);
        closeLoader();
      } else {
        setPercentage(newPercentage);
      }
    }
  }, [fontsPercentage, imagesLoaded]);

  const imgLoaded = () => {
    setImagesLoaded((prev) => prev + 4);
  };
  let interval;
  const fallbackTimeout = useRef(null);
  // loader closing stages
  useEffect(() => {
    clearTimeout(fallbackTimeout.current);
    fallbackTimeout.current = setTimeout(() => {
      if (loading === "open") {
        setPercentage(100);
        closeLoader();
      }
    }, 90000);
    return () => {
      clearTimeout(fallbackTimeout.current);
    };
  }, []);

  const closeLoader = () => {
    clearInterval(interval);
    clearTimeout(fallbackTimeout.current);
    setLoading("closed");
    document.body.setAttribute("data-loader", "ended");
    setTimeout(() => {
      setLoading("removed");
      document.body.setAttribute("data-logo", "page");
    }, 2500);
  };

  // calculate loading percentage
  useEffect(() => {
    interval = setInterval(() => calculatePercentage(), 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const fontsToCheck = [
    "18px Extended Semi Bold Neue Plak",
    "18px Bold Neue Plak",
    "18px Condensed Neue Plak SemiBold",
    "18px Extended Thin Neue Plak",
    "18px Extended Bold Neue Plak",
    "18px Neue Plak",
    "18px Condensed bold Neue Plak",
    "18px Condensed Neue Plak",
    "18px Extended Light Neue Plak",
    "18px Extended Regular Neue Plak",
    "18px Extended Thin Neue Plak",
  ];
  const fontsToPreload = [
    "Extended Semi Bold Neue Plak",
    "Bold Neue Plak",
    "Condensed Neue Plak SemiBold",
    "Extended Thin Neue Plak",
    "Extended Bold Neue Plak",
    "Neue Plak",
    "Condensed bold Neue Plak",
    "Condensed Neue Plak",
    "Extended Light Neue Plak",
    "Extended Regular Neue Plak",
    "Extended Thin Neue Plak",
  ];

  const calculatePercentage = () => {
    let total = 4;
    fontsToCheck.forEach((font) => {
      if (document.fonts.check(font)) {
        total++;
      }
    });
    setFontsPercentage(total * 4);
  };

  // js media query
  const [matches425, setMatches425] = useState(
    window.matchMedia("(max-width: 425px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(max-width: 425px)")
      .addEventListener("change", (e) => setMatches425(e.matches));
  }, [matches425]);

  // move loader logo
  const containerRef = useRef();

  useEffect(() => {
    if (document.body.getAttribute("data-loader") === "ended") {
      setTimeout(() => {
        containerRef.current.style.left = `${document.body.getAttribute(
          "data-position-x"
        )}px`;
      }, 10);
    }
  }, [document.body.getAttribute("data-loader")]);

  useEffect(() => {
    if (document.body.getAttribute("data-loader") === "ended") {
      setTimeout(() => {
        containerRef.current.style.top = `${document.body.getAttribute(
          "data-position-y"
        )}px`;
      }, 10);
    }
  }, [document.body.getAttribute("data-loader")]);

  const imagesToPreload = [
    Ai,
    Systems,
    apps,
    mobile,
    penetration,
    testing,
    development,
    software,
    analysis,
    erp,
    system,
    ui,
    ux,
  ];

  return (
    <>
      <div className={`loader-bg ${loading}`}>
        <div
          className="loader-content loader-logo-container"
          ref={containerRef}
          style={
            document.body.getAttribute("data-loader") === "ended"
              ? {
                  top: `${Number(
                    document.body.getAttribute("data-position-y")
                  )}px`,
                  // left: `${Number(
                  //   document.body.getAttribute("data-position-x")
                  // )}px`,
                  transform: "translate(0,0)",
                }
              : {}
          }
        >
          <Logo className="loader-logo" isLogo={true} />
          <Percentage percentage={percentage} />
          <LoadCounter updateInterval={15} after="%" value={percentage} />
        </div>
      </div>
      <div>
        {fontsToPreload.map((font, index) => {
          return (
            <p
              key={index}
              style={{
                visibility: "hidden",
                position: "fixed",
                top: "0",
                left: "0",
                transform: "translate(-100w, -100vh)",
                fontFamily: font,
              }}
            >
              _
            </p>
          );
        })}
        {imagesToPreload.map((image, index) => {
          return (
            <img
              key={index}
              style={{
                visibility: "hidden",
                position: "fixed",
                top: "0",
                left: "0",
                width: "0",
                height: "0",
                transform: "translate(-100w, -100vh)",
              }}
              onLoad={imgLoaded}
              src={image}
              alt="preload"
            />
          );
        })}
      </div>
    </>
  );
};
export default Loader;
