export const Animation = (count, setCount) => {
  let allowed = false;
  if ("ontouchstart" in window) {
    let ts;
    document.body?.addEventListener("touchstart", (e) => {
      ts = e.touches[0].clientY;
    });
    document.body?.addEventListener(
      "touchmove",
      (e) => {
        let te = e.changedTouches[0].clientY;
        if (allowed) {
          if (
            document.body.scrollHeight - document.body.scrollTop ===
            document.body.clientHeight
          ) {
            if (ts > te + 100) {
              console.log("ts:", ts, " ", "te:", te);
              if (count < 4 && count >= 0) {
                allowed = false;
                setCount(count + 1);
              }
            } else if (ts < te - 100) {
              if (count !== 0) {
                allowed = false;
                setCount(count - 1);
              }
            }
          }
        }
      },
      { passive: false }
    );
    setTimeout(() => {
      allowed = true;
    }, 2000);

    let loader = document.getElementById("loader");

    if (count === 2) {
      loader.style.opacity = 1;
      setTimeout(() => {
        loader.style.opacity = 0;
      }, 800);
    }

    if (count === 3) {
      loader.style.opacity = 1;
      setTimeout(() => {
        loader.style.opacity = 0;
      }, 800);
    }

    if (count === 4) {
      loader.style.opacity = 1;
      setTimeout(() => {
        loader.style.opacity = 0;
      }, 800);
    }
    // if (count === 5) {
    //   loader.style.opacity = 1;
    //   setTimeout(() => {
    //     loader.style.opacity = 0;
    //   }, 800);
    // }
  } else {
    document.getElementById("home").addEventListener(
      "wheel",
      (e) => {
        if (allowed) {
          if (e.deltaY > 0) {
            if (count < 4 && count >= 0) {
              allowed = false;
              setCount(count + 1);
            }
          } else if (e.deltaY < 0) {
            if (count !== 0) {
              allowed = false;
              setCount(count - 1);
            }
          }
        }
      },
      { passive: false }
    );
    setTimeout(() => {
      allowed = true;
    }, 2000);

    let loader = document.getElementById("loader");

    if (count === 2) {
      loader.style.opacity = 1;
      setTimeout(() => {
        loader.style.opacity = 0;
      }, 800);
    }

    if (count === 3) {
      loader.style.opacity = 1;
      setTimeout(() => {
        loader.style.opacity = 0;
      }, 800);
    }

    if (count === 4) {
      loader.style.opacity = 1;
      setTimeout(() => {
        loader.style.opacity = 0;
      }, 800);
    }
    // if (count === 5) {
    //   loader.style.opacity = 1;
    //   setTimeout(() => {
    //     loader.style.opacity = 0;
    //   }, 800);
    // }
  }
};
