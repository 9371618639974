import { Flex, Image, Text } from "@chakra-ui/react";
import facebook from '../../assets/vectors/facebook.svg'
import instagram from '../../assets/vectors/instagram.svg'
import linkedin from '../../assets/vectors/linkedin.svg';
const Footer = () => {

    return (
        <Flex id='footer' w='100%' zIndex={1000000} position={'fixed'} bottom={'40px'} paddingInline={['20px', '30px', '50px', '50px', '50px']} justifyContent={'space-between'} alignItems={'center'}>

            <Flex gap={['10px', '15px', '20px', '20px', '20px']}>
                <a rel="noreferrer" target="_blank" href='https://www.facebook.com/profile.php?id=100083502873412' style={{ cursor: 'pointer' }}><Image w={['50%', '80%', '80%', '100%', '100%']} src={facebook} alt='' /></a>
                <a rel="noreferrer" target="_blank" href='https://www.instagram.com/pan.code' style={{ cursor: 'pointer' }}><Image w={['50%', '80%', '80%', '100%', '100%']} src={instagram} alt='' /></a>
                <a rel="noreferrer" target="_blank" href='https://www.linkedin.com/company/pancode/' style={{ cursor: 'pointer' }}><Image w={['50%', '80%', '80%', '100%', '100%']} src={linkedin} alt='' /></a>
            
            </Flex>

            <Flex>
                <Text color={'white'} fontFamily={'Condensed bold Neue Plak'} fontWeight={'400'} fontSize={['12px', '15px', '20px', '24px', '24px']}>PanCode {new Date().getFullYear()} © . All Rights Reseved</Text>
            </Flex>

        </Flex>
    )
}
export default Footer;