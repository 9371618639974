import { useContext } from "react";
import { CountContext } from "../../App";
import ourStory3 from "../../assets/images/OurStory3.jpg";
import { Box, Flex, Heading, Image } from "@chakra-ui/react";
import ourMission from "../../assets/vectors/OurMission.svg";
import robot from "../../assets/vectors/robot.svg";

const OurMission = () => {
  const { count } = useContext(CountContext);

  return (
    <>
      <Box
        w={"100%"}
        h="100%"
        backgroundImage={`url(${ourStory3})`}
        backgroundSize={"cover"}
        backgroundPosition={"center center"}
        cursor={"default"}
        userSelect={"none"}
        backgroundAttachment={"absolute"}
        left="50%"
        top={"50%"}
        right={"0"}
        bottom={"0"}
        transform={"translate(-50%,-50%)"}
        position={"absolute"}
        zIndex={"-1"}
        transition={count !== 3 ? "all 1s linear" : "all 1s linear 1s"}
        opacity={count === 3 ? 1 : 0}
        backgroundRepeat={"no-repeat"}
      ></Box>

      <Box
        id="ourStory3"
        animation={"2.2s ease-in 0s 1 fade"}
        position={"absolute"}
        top={"20%"}
        display={count === 3 ? "flex" : "none"}
        gap="20px"
        flexDirection={"column"}
      >
        <Image
          mb={"50px"}
          paddingInline={["50px", "0px", "0px", "0px", "0px"]}
          minW={"1000px"}
          src={ourMission}
          alt=""
        />
        <h2 className="sr-only">Our Mission</h2>
        <Flex
          animation={"1.3s ease-in 0.4s 1 robot"}
          id="ourStory3-logo"
          justifyContent={"center"}
        >
          <Image
            w={["30px", "35px", "40px", "40px", "40px"]}
            h="31px"
            src={robot}
            alt=""
          />
        </Flex>
        <Box alignSelf={"center"} w={["27%", "34%", "34%", "34%", "34%"]}>
          <Heading
            textAlign={"center"}
            m="0px"
            fontFamily={"Condensed Neue Plak"}
            color={"#FFFFFF"}
            fontSize={["14px", "15px", "17px", "18px", "18px"]}
            letterSpacing={"0.19rem"}
          >
            is about building a{" "}
            <span style={{ color: "#BAB300" }}>quality</span> product, trusted
            relationships with our customers, and a sense of community that
            connects our customers and team with one another.
          </Heading>
        </Box>
      </Box>
    </>
  );
};
export default OurMission;
