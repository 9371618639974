import ourStory2 from '../../assets/images/OurStory2.jpg'
import { useContext} from 'react';
import { CountContext } from '../../App';
import robot from '../../assets/vectors/robot.svg'
import { Box, Flex, Heading, Image } from '@chakra-ui/react';

const OurPassion = () => {
    const { count } = useContext(CountContext)

    return (
        <>
            <Box w={'100%'} h='100%' backgroundImage={`url(${ourStory2})`} backgroundSize={'cover'} backgroundPosition={'center center'} cursor={'default'} userSelect={'none'} backgroundAttachment={'absolute'} left='50%' top={'50%'} right={'0'} bottom={'0'} transform={'translate(-50%,-50%)'} position={'absolute'} zIndex={'-1'} transition={count !== 2 ? 'all 1s linear' : 'all 1s linear 1s'} opacity={count === 2 ? 1 : 0} backgroundRepeat={'no-repeat'} >
            </Box>

            <Box id='ourStory2' animation={'2.2s ease-in 0s 1 fade'} position={'absolute'} top={'40%'} display={count === 2 ? 'flex' : 'none'} gap='20px' flexDirection={'column'}>
                <Flex animation={'1.3s ease-in 0.4s 1 robot'} id='ourStory2-logo' justifyContent={'center'}><Image w={['30px', '35px', '40px', '40px', '40px']} h='31px' src={robot} alt='' /></Flex>
                <Box alignSelf={'center'} w={['60%', '40%', '34%', '34%', '34%']} ><Heading textAlign={'center'} m='0px' fontFamily={'Condensed Neue Plak'} color={'#FFFFFF'} fontSize={['14px','15px','17px','18px','18px']} letterSpacing={'0.19rem'}>Our <span style={{ fontSize: '17px', color: 'black' }}>passion</span> for making meaningful connections flows through everything we do.</Heading></Box>
            </Box>
        </>
    )
}
export default OurPassion;