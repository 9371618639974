import { Box, Flex } from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { CountContext } from "../App";
import "../assets/styles/home.css";
import OurMission from "../components/Home/OurMission";
import OurPassion from "../components/Home/OurPassion";
import TheTeam from "../components/Home/TheTeam";
import WeDoThingsDifferently from "../components/Home/WeDoThingsDifferently";
import WelcomeToPancode from "../components/Home/WelcomeToPancode";
import Loader from "../components/common/Loader";
import Footer from "../components/layouts/Footer";
import { Animation } from "../util/Animation";
import OurPartners from "../components/Home/partners/OurPartners";
import { Keyboard } from "../util/Keyboard";
import { Fullscreen } from "../util/Fullscreen";

const Home = () => {
  document.title = "Home";
  const { count, setCount } = useContext(CountContext);

  useEffect(() => {
    Animation(count, setCount);
  }, [count, setCount]);

  Keyboard(setCount, count);
  if (window.location.pathname === "/") {
    document.body.style.height = "100vh";
    document.body.style.overflow = "hidden";
  }
  return (
    <Box onDoubleClick={Fullscreen} position={"relative"} overflow={"hidden"}>
      <Box id="home" w="100%" h="100vh" position={"fixed"}>
        <Loader />
        <Flex
          h="100%"
          w="100%"
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <WelcomeToPancode />
          <WeDoThingsDifferently />
          <OurPassion />
          <OurMission />
          <OurPartners />
          {/* <TheTeam /> */}
        </Flex>
      
      </Box>
    </Box>
  );
};
export default Home;
