import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Services from "./pages/Services";
import { createContext, useState } from "react";
import Header from "./components/layouts/Header";
import ContactUs from "./pages/ContactUs";
import Loader from "./components/loader/Loader";
import Error404 from "./pages/Error404";
export const CountContext = createContext();
function App() {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState("open");

  return (
    <CountContext.Provider value={{ count, setCount }}>
      <Router>
        <Loader setLoading={setLoading} loading={loading} />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </Router>
    </CountContext.Provider>
  );
}

export default App;
