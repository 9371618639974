import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { EffectCoverflow, Keyboard, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import { Swiper, SwiperSlide } from "swiper/react";

//ai systems
import Ai from "../../../assets/images/services/ai systems/ai.png";
import Systems from "../../../assets/images/services/ai systems/systems.png";

//mobile apps
import apps from "../../../assets/images/services/mobile apps/apps.png";
import mobile from "../../../assets/images/services/mobile apps/mobile.png";

//penetration testing
import penetration from "../../../assets/images/services/penetration testing/penetration.png";
import testing from "../../../assets/images/services/penetration testing/testing.png";

//software development
import development from "../../../assets/images/services/software development/development.png";
import software from "../../../assets/images/services/software development/software.png";

//system analysis & erp
import analysis from "../../../assets/images/services/system analysis & erp/analysis.png";
import erp from "../../../assets/images/services/system analysis & erp/erp.png";
import system from "../../../assets/images/services/system analysis & erp/system.png";

//ui ux
import ui from "../../../assets/images/services/ui ux/ui.png";
import ux from "../../../assets/images/services/ui ux/ux.png";

import { useNavigate } from "react-router-dom";
import "../../../assets/styles/ourServices.css";
import IconLeft from "../../common/IconLeft";
import IconRight from "../../common/IconRight";
const OurServices = () => {
  const [swiperRef, setSwiperRef] = useState();
  const [matchMobile] = useMediaQuery(["(max-width: 480px)"]);
  const [realIndex, setRealIndex] = useState(1);
  const navigate = useNavigate();
  const handlePrevious = useCallback(() => {
    swiperRef?.slidePrev();
    setRealIndex(swiperRef.activeIndex);
  }, [swiperRef]);

  const handleNext = useCallback(() => {
    swiperRef?.slideNext();
    setRealIndex(swiperRef.activeIndex);
  }, [swiperRef]);
  return (
    <Box
      className="ourservices"
      h="100%"
      position={"relative"}
      overflow={"unset"}
    >
      <Box
        className="cursor-hover"
        zIndex={"1000"}
        cursor={"pointer"}
        onClick={handlePrevious}
        left={["8%", "13%", "25%", "28%", "28%"]}
        top={["12%", "12%", "12%", "12%", "18%"]}
        position={"absolute"}
      >
        <IconLeft width={matchMobile ? "25" : "35"} opacity={"0.66"} />
      </Box>
      <Swiper
        initialSlide={"1"}
        onTransitionEnd={() =>
          setRealIndex(
            swiperRef?.realIndex !== undefined ? swiperRef?.realIndex : 1
          )
        }
        onSwiper={setSwiperRef}
        grabCursor={true}
        coverflowEffect={{
          slideShadows: false,
          depth: 0,
        }}
        keyboard={{ enabled: true }}
        modules={[Keyboard, EffectCoverflow, Navigation]}
        className="mySwiper "
        slidesPerView={[2.3]}
        spaceBetween={200}
        style={{
          overflow: "unset",
          position: "relative",
          left: "-200px",
          zIndex: 100,
        }}
        loop={true}
      >
        <SwiperSlide>
          <Box
            h="100%"
            marginTop={"80px"}
            position="relative"
            transition={"all 1s ease"}
            transform={realIndex !== 6 ? "" : "scale(1.1)"}
            opacity={realIndex !== 6 ? "0.5" : "1"}
          >
            <Box
              minW={"200px"}
              maxW={"370px"}
              boxShadow={"none !important"}
              left={["27px", "0px", "0px", "0px", "0px"]}
              position={"absolute"}
              zIndex={"3"}
              top={"-55px"}
              animation={"float 6s ease-out infinite"}
            >
              <Image
                w={"100%"}
                boxShadow={"none !important"}
                src={mobile}
                alt="Mobile"
              />
            </Box>

            <Box
              minW={"165px"}
              maxW={"350px"}
              position={"absolute"}
              left={["50px", "30px", "30px", "30px", "30px"]}
              top={matchMobile ? "20px" : "85px"}
              zIndex={"2"}
              animation={"float 6s ease-in infinite"}
            >
              <Image w={"100%"} src={apps} alt="Apps" />
              <Box
                zIndex={"2"}
                position="absolute"
                top="20%"
                left="50%"
                transform="translate(-50%,-50%)"
                background={"#C0F5F8"}
                animation={"5s ease-in light infinite"}
                w={matchMobile ? "250px" : "200px"}
                h={matchMobile ? "300px" : "200px"}
              ></Box>
            </Box>
          </Box>
        </SwiperSlide>

        <SwiperSlide>
          <Box
            p={matchMobile ? "50px" : "0px"}
            transition={"all 1s ease"}
            marginTop={"120px"}
            position="relative"
            transform={
              realIndex === 1
                ? "scale(0.8)"
                : realIndex === 6
                ? "scale(0.8) translateX(30px)"
                : ""
            }
            opacity={realIndex !== 0 ? "0.5" : "1"}
          >
            <Box
              minW={"250px"}
              maxW="500px"
              boxShadow={"none !important"}
              position={"absolute"}
              zIndex={"3"}
              left={["20px", "0px", "-25px", "-25px", "-25px"]}
              top="-100px"
              animation={"float 6s ease-out infinite"}
            >
              <Image
                w="100%"
                boxShadow={"none !important"}
                src={penetration}
                alt="Penetration"
              />
            </Box>

            <Box
              minW={"180px"}
              maxW="400px"
              position={"absolute"}
              left={["44px", "0px", "25px", "25px", "25px"]}
              top={["-30px", "0px", "35px", "35px", "35px"]}
              zIndex={"2"}
              animation={"float 6s ease-in infinite"}
            >
              <Image w="100%" src={testing} alt="Testing" />
              <Box
                zIndex={"2"}
                position="absolute"
                top="20%"
                left="50%"
                transform="translate(-50%,-50%)"
                background={"#C0F5F8"}
                animation={"5s ease-in light infinite"}
                w={matchMobile ? "250px" : "200px"}
                h={matchMobile ? "300px" : "200px"}
              ></Box>
            </Box>
          </Box>
        </SwiperSlide>

        <SwiperSlide>
          <Box
            p={matchMobile ? "50px" : "0px"}
            marginTop={"80px"}
            position="relative"
            transition={"all 1s ease"}
            transform={realIndex !== 1 ? "" : "scale(1.1)"}
            opacity={realIndex !== 1 ? "0.5" : "1"}
          >
            <Box
              minW={"200px"}
              maxW="400px"
              boxShadow={"none !important"}
              position={"absolute"}
              zIndex={"3"}
              left={["40px", "20px", "45px", "45px", "45px"]}
              top="-55px"
              animation={"float 6s ease-in infinite"}
            >
              <Image w="100%" boxShadow={"none !important"} src={Ai} alt="AI" />
            </Box>

            <Box
              minW={"200px"}
              maxW="400px"
              position={"absolute"}
              left={"30px"}
              top={["-10px", "0px", "10px", "10px", "15px"]}
              zIndex={"2"}
              animation={"float 6s ease-out infinite"}
            >
              <Image w="100%" src={Systems} alt="Systems" />
              <Box
                zIndex={"2"}
                position="absolute"
                top="20%"
                left="50%"
                transform="translate(-50%,-50%)"
                background={"#C0F5F8"}
                animation={"5s ease-in light infinite"}
                w={matchMobile ? "250px" : "200px"}
                h={matchMobile ? "300px" : "200px"}
              ></Box>
            </Box>
          </Box>
        </SwiperSlide>

        <SwiperSlide>
          <Box
            p={matchMobile ? "50px" : "0px"}
            transition={"all 1s ease"}
            marginTop={"80px"}
            position="relative"
            transform={
              realIndex === 3
                ? "scale(0.8) translateX(-10px)"
                : realIndex === 1
                ? "scale(0.8) translateX(30px)"
                : ""
            }
            opacity={realIndex !== 2 ? "0.5" : "1"}
          >
            <Box
              minW={"325px"}
              maxW="650px"
              boxShadow={"none !important"}
              position={"absolute"}
              left={["-32px", "-30px", "0px", "0px", "-130px"]}
              zIndex={"3"}
              top={["-100px", "-100px", "-100px", "-100px", "-160px"]}
              animation={"float 6s ease-out infinite"}
            >
              <Image
                w="100%"
                boxShadow={"none !important"}
                src={software}
                alt="Software"
              />
            </Box>

            <Box
              minW={"275px"}
              maxW="550px"
              left={["0px", "30px", "25px", "25px", "-50px"]}
              position={"absolute"}
              top={["-60px", "-30px", "-50px", "-50px", "-100px"]}
              zIndex={"2"}
              animation={"float 6s ease-in infinite"}
            >
              <Image w="100%" src={development} alt="Development" />
              <Box
                zIndex={"2"}
                position="absolute"
                top="20%"
                left="50%"
                transform="translate(-50%,-50%)"
                background={"#C0F5F8"}
                animation={"5s ease-in light infinite"}
                w={matchMobile ? "250px" : "200px"}
                h={matchMobile ? "300px" : "200px"}
              ></Box>
            </Box>
          </Box>
        </SwiperSlide>

        <SwiperSlide>
          <Box
            p={matchMobile ? "50px" : "0px"}
            marginTop={"80px"}
            position="relative"
            transition={"all 1s ease"}
            transform={realIndex !== 3 ? "" : "scale(1.1) translateX(-15px)"}
            opacity={realIndex !== 3 ? "0.5" : "1"}
          >
            <Box
              minW={"175px"}
              maxW="350px"
              boxShadow={"none !important"}
              position={"absolute"}
              zIndex={"3"}
              left={["55px", "40px", "90px", "90px", "30px"]}
              top="-50px"
              animation={"float 6s ease-out infinite"}
            >
              <Image
                w="100%"
                boxShadow={"none !important"}
                src={system}
                alt="System"
              />
            </Box>

            <Box
              minW={"185px"}
              maxW="350px"
              position={"absolute"}
              top={["-10px", "-10px", "0px", "0px", "20px"]}
              left={["50px", "60px", "90px", "90px", "30px"]}
              zIndex={"2"}
              animation={"float 6s ease-in infinite"}
            >
              <Image w="100%" src={analysis} alt="Analysis" />
              <Box
                zIndex={"2"}
                position="absolute"
                top="20%"
                left="50%"
                transform="translate(-50%,-50%)"
                background={"#C0F5F8"}
                animation={"5s ease-in light infinite"}
                w={matchMobile ? "250px" : "200px"}
                h={matchMobile ? "300px" : "200px"}
              ></Box>
            </Box>
          </Box>
        </SwiperSlide>

        <SwiperSlide>
          <Box
            p={matchMobile ? "50px" : "0px"}
            marginTop={"80px"}
            position="relative"
            transition={"all 1s ease"}
            transform={realIndex !== 4 ? "" : "scale(1.1)"}
            opacity={realIndex !== 4 ? "0.5" : "1"}
          >
            <Box
              minW={"165px"}
              maxW="330px"
              boxShadow={"none !important"}
              position={"absolute"}
              zIndex={"3"}
              left={["48px", "30px", "65px", "65px", "40px"]}
              top="-50px"
              animation={"float 6s ease-out infinite"}
            >
              <Image
                w="100%"
                boxShadow={"none !important"}
                src={erp}
                alt="ERP"
              />
            </Box>

            <Box
              minW={"195px"}
              maxW="350px"
              position={"absolute"}
              top={["-20px", "-10px", "0px", "0px", "0px"]}
              left={["33px", "40px", "65px", "65px", "25px"]}
              zIndex={"2"}
              animation={"float 6s ease-in infinite"}
            >
              <Image w="100%" src={Systems} alt="Systems" />
              <Box
                zIndex={"2"}
                position="absolute"
                top="20%"
                left="50%"
                transform="translate(-50%,-50%)"
                background={"#C0F5F8"}
                animation={"5s ease-in light infinite"}
                w={matchMobile ? "250px" : "200px"}
                h={matchMobile ? "300px" : "200px"}
              ></Box>
            </Box>
          </Box>
        </SwiperSlide>

        <SwiperSlide>
          <Box
            p={matchMobile ? "50px" : "0px"}
            marginTop={"80px"}
            position="relative"
            transition={"all 1s ease"}
            transform={realIndex !== 5 ? "" : "scale(1.1)"}
            opacity={realIndex !== 5 ? "0.5" : "1"}
          >
            <Box
              minW={"215px"}
              maxW="430px"
              boxShadow={"none !important"}
              position={"absolute"}
              zIndex={"3"}
              left={["30px", "0px", "55px", "55px", "0px"]}
              top={"-60px"}
              animation={"float 6s ease-out infinite"}
            >
              <Image w="100%" boxShadow={"none !important"} src={ui} alt="UI" />
            </Box>

            <Box
              minW={"200px"}
              maxW="400px"
              position={"absolute"}
              top={["-10px", "-10px", "30px", "30px", "30px"]}
              left={["40px", "40px", "55px", "55px", "20px"]}
              zIndex={"2"}
              animation={"float 6s ease-in infinite"}
            >
              <Image w="100%" src={ux} alt="UX" />
              <Box
                zIndex={"2"}
                position="absolute"
                top="20%"
                left="50%"
                transform="translate(-50%,-50%)"
                background={"#C0F5F8"}
                animation={"5s ease-in light infinite"}
                w={matchMobile ? "250px" : "200px"}
                h={matchMobile ? "300px" : "200px"}
              ></Box>
            </Box>
          </Box>
        </SwiperSlide>
      </Swiper>

      <Box
        className="cursor-hover"
        cursor={"pointer"}
        zIndex={"1000"}
        onClick={handleNext}
        position={"absolute"}
        right={["8%", "13%", "25%", "28%", "28%"]}
        top={["12%", "12%", "12%", "12%", "18%"]}
      >
        <IconRight width={matchMobile ? "25" : "35"} opacity={"0.66"} />
      </Box>

      <Box
        h={["100%", "100%", "100vh", "100vh", "100vh"]}
        position={"relative"}
        top={["100px", "200px", "300px", "300px", "300px"]}
      >
        <Stack spacing={"10vh"}>
          <Flex
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Text
              paddingInline={matchMobile ? "20px" : "50px"}
              textAlign={"center"}
              fontFamily={"Extended Light Neue Plak"}
              fontSize={["15px", "20px", "20px", "20px", "20px"]}
              color={"white"}
            >
              {realIndex === 0
                ? "A type of security testing that is used to identify vulnerabilities in software applications, networks, systems, and infrastructure. At our company, penetration testing is a critical service that helps clients identify and address potential security threats before they can be exploited by attackers"
                : realIndex === 1
                ? "Software program that is designed to enhance the user experience and improve website performance using artificial intelligence techniques. These systems can use machine learning, natural language processing, and other AI technologies to analyze user behavior, personalize content, and automate certain tasks."
                : realIndex === 2
                ? "Our Software development team can encompass a wide range of technologies and programming languages, depending on the specific needs of the client. The goal is always to create high-quality, scalable software solutions that help clients achieve their business objectives."
                : realIndex === 3
                ? "Our System analysis team helps to ensure the success of the new software solution. By thoroughly understanding the client's existing systems and requirements, also they can create a solution that addresses their specific needs and delivers measurable business value."
                : realIndex === 4
                ? "The software development team works closely with clients to understand their specific business needs and requirements, and then creates a customized ERP solution that meets those needs. our company also provides ongoing support and maintenance to ensure the ERP system remains up to date and continues to meet the needs of the client. "
                : realIndex === 5
                ? "Our specialized UI/UX designers create custom software applications tailored to your unique business goals. We deliver high-quality design solutions that increase user satisfaction and engagement, helping you achieve your objectives. Partner with us to elevate your user experience."
                : realIndex === 6
                ? "Our Mobile application team requires expertise in mobile platforms, programming languages, and development frameworks .and they specialize in one or more mobile platforms, such as iOS or Android, and use a variety of tools and technologies to create high-quality mobile applications that meet the needs of the client and provide a positive user experience."
                : ""}
            </Text>
          </Flex>

          <Box w="100%" h="400px">
            <Flex
              flexDirection={"column"}
              position={"relative"}
              gap={"20px"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Text
                color={"white"}
                w={matchMobile ? "100%" : "100%"}
                paddingInline={matchMobile ? "0px" : "15px"}
                textAlign={"center"}
                fontFamily={"Extended Bold Neue Plak"}
                fontSize={["18px", "20px", "20px", "20px", "20px"]}
              >
                Our Services cover a wide range of topics to insure a high level
                of user experience and trust between us and You.
              </Text>
              <Heading
                fontFamily={"Condensed bold Neue Plak"}
                color={"white"}
                textAlign={"center"}
                alignItems={"center"}
                fontSize={["35px", "40px", "60px", "90px", "90px"]}
              >
                “ Take action on your ideas “
              </Heading>
              <Box
                className="cursor-hover"
                position={"relative"}
                zIndex={10}
                cursor={"pointer"}
              >
                <Button
                  onClick={() => navigate("/contact-us")}
                  w="120px"
                  h="45px"
                  fontWeight={"400"}
                  borderRadius={"0px"}
                  borderBottomRightRadius={"25px"}
                  fontFamily={"Extended Bold Neue Plak"}
                  bg="white"
                >
                  Reach Us
                </Button>
              </Box>
            </Flex>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
export default OurServices;
