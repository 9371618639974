import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import robot from "../../assets/vectors/robot.svg";
import Logo from "../../components/common/Logo";

const Intro = () => {
  const [matches600, setMatches600] = useState(
    window.matchMedia("(max-width: 600px)").matches
  );
  useEffect(() => {
    window
      .matchMedia("(max-width: 600px)")
      .addEventListener("change", (e) => setMatches600(e.matches));
  }, [matches600]);

  return (
    <>
      <Box
        id="planet"
        w={"100%"}
        h="100vh"
        bg={`radial-gradient(${
          matches600 ? "200%" : "83.55%"
        } 93.55% at 49.97% 115.26%, #000000 ${
          matches600 ? "20%" : "0%"
        }, #49C2C7 51.34%, #49C2C7 56.29%, #8E5F5F 88.33%, rgba(0, 0, 0, 0) 100%);`}
        backgroundSize={"100% 100%"}
        left="50%"
        top={["23%", "25%", "25%", "26.5%", "25%"]}
        right={"0"}
        bottom={"0"}
        transform={"translate(-50%,-50%)"}
        position={"absolute"}
        zIndex={"1"}
        backgroundRepeat={"no-repeat"}
        animation=" 3s ease-out 0s 1 fade"
      ></Box>

      <Box
        mixBlendMode={"color-burn"}
        bottom={"0"}
        right={"0"}
        left={"50%"}
        top={["44.8%", "50%", "47%", "48%", "47%"]}
        transform={"translate(-50%,-50%)"}
        w={"100%"}
        h={["7%", "3%", "5%", "5%", "5%"]}
        bg="linear-gradient(360deg, #000000 20.36%, rgba(0, 0, 0, 0) 100%);"
        position={"absolute"}
        zIndex={"1"}
        backgroundSize={"100% 100%"}
      ></Box>

      <Box className="services" id="services" w="100%" h="100vh">
        <Box
          zIndex={"2"}
          w="100%"
          position={"absolute"}
          top={["90px", "70px", "70px", "70px", "70px"]}
        >
          <Text
            id="welcome"
            transition={"all 0.5s"}
            textAlign={"center"}
            fontWeight={"400"}
            opacity={"1"}
            fontFamily={"Extended Thin Neue Plak"}
            fontSize={["12px", "17px", "25px", "25px", "25px"]}
            letterSpacing={"0.1rem"}
            color={"white"}
            bg="transparent"
            as="h2"
          >
            Services
          </Text>
          <Flex
            justifyContent={"center"}
            mt={["0px", "20px", "20px", "20px", "20px"]}
            alignItems={"center"}
            id="logo"
          >
            <Logo size={"400px"} setPosition={true} />
            <h1 className="sr-only">PanCode</h1>
          </Flex>
        </Box>
      </Box>

      <Box
        w="100%"
        animation=" 3s ease-out 0s 1 fade"
        position={"absolute"}
        justifyContent={"center"}
        top={["25%", "22.5%", "22.5%", "22.5%", "22.5%"]}
        display={"flex"}
        gap="20px"
        zIndex={"2"}
        flexDirection={"column"}
      >
        <Flex justifyContent={"center"}>
          <Image
            w={["30px", "35px", "40px", "40px", "40px"]}
            h="31px"
            src={robot}
            alt=""
          />
        </Flex>
        <Box alignSelf={"center"} w={["85%", "75%", "55%", "34%", "27%"]}>
          <Heading
            textAlign={"center"}
            fontWeight={"300"}
            m="0px"
            fontFamily={"Condensed Neue Plak"}
            color={"#FFFFFF"}
            fontSize={["16px", "23px", "23px", "23px", "23px"]}
            letterSpacing={"0.19rem"}
          >
            We’ll Transform Your Ideas Into Projects And Your Projects Into Real
            World Businesses
          </Heading>
        </Box>
      </Box>
    </>
  );
};
export default Intro;
