import { useEffect } from "react";
import "../assets/styles/services.css";
import Cursor from "../util/Cursor";
import { ParallaxStars } from "../util/ParallaxStars";
import Intro from "../components/Services/Intro";
import OurServices from "../components/Services/ourServices/OurServices";
import { Box } from "@chakra-ui/react";

import "swiper/css";
import "swiper/css/pagination";
import { Fullscreen } from "../util/Fullscreen";
// import required modules
const Services = () => {
  document.title = "Services";

  useEffect(() => {
    ParallaxStars();
    Cursor();
  });
  if (window.location.pathname === "/services") {
    document.body.style.height = "";
    document.body.style.overflow = "auto";
  }
  return (
    <>

      <div id="ball" className="ball"></div>
      {/* <div className="moon">
                <div className="details"></div>
                <div className="details one"></div>
                <div className="details two"></div>
                <div className="details three"></div>
                <div className="details four small"></div>
            </div> */}

      <div id="bg-stars" className="bg-stars">
        <div id="stars"></div>
        <div id="stars2"></div>
        <div id="stars3"></div>
        <div id="stars4"></div>
      </div>

      <Box onDoubleClick={Fullscreen} overflow={"hidden"}>
        <Intro />
        <OurServices />
      </Box>
    </>
  );
};
export default Services;
