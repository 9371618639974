import { useContext, useEffect, useState } from "react";
import { CountContext } from "../../App";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import Logo from "../common/Logo";
import { useNavigate } from "react-router-dom";
const WelcomeToPancode = () => {
  const { count } = useContext(CountContext);
  const [matches425, setMatches425] = useState(
    window.matchMedia("(max-width: 425px)").matches
  );
  useEffect(() => {
    window
      .matchMedia("(max-width: 425px)")
      .addEventListener("change", (e) => setMatches425(e.matches));
  }, [matches425]);
  const navigate = useNavigate();
  return (
    <>
      <Box
        w={["500%", "400%", "240%", "200%", "200%"]}
        h="100%"
        animation=" 3s ease-out 0s 1 fade"
        bg="linear-gradient(to bottom right, #000000 0%, #1C4A4C 14%, #49C2C7 30% , #000000 44%) 99.9%, linear-gradient(to bottom left, #000000 0%, #1C4A4C 14%, #49C2C7 30%, #000000 44%) 0%;"
        backgroundSize={"50% 100%"}
        left="50%"
        top={"50%"}
        right={"0"}
        bottom={"0"}
        transform={"translate(-50%,-50%)"}
        opacity={count === 0 ? 1 : 0}
        position={"absolute"}
        zIndex={"-1"}
        backgroundRepeat={"no-repeat"}
      ></Box>

      <Box
        w="100%"
        left={"50%"}
        animation=" 2s ease-in fade"
        transition={"all 0.5s"}
        transform={
          count === 0 ? "translate3d(0px,0px,0)" : "translate3d(10px,-165px,0)"
        }
      >
        <Text
          id="welcome"
          transition={"all 0.5s"}
          textAlign={"center"}
          mt={["100px", "100px", "100px", "100px", "100px"]}
          fontWeight={"400"}
          opacity={"1"}
          fontFamily={"Extended Thin Neue Plak"}
          letterSpacing={"0.1rem"}
          fontSize={
            count !== 0 ? "35px" : ["14px", "14px", "25px", "25px", "25px"]
          }
          color={"white"}
          bg="transparent"
        >
          Welcome to
        </Text>
        <Flex
          mr={matches425 ? "7px" : "10px"}
          transition={"all 0.5s"}
          justifyContent={"center"}
          mt={count !== 0 ? "50px" : ["0px", "5px", "10px", "10px", "10px"]}
          alignItems={"center"}
          id="logo"
        >
          <Logo
            wSize={400}
            hSize={count !== 0 ? (matches425 ? "15px" : "15px") : "62px"}
            setPosition={true}
          />
          <h1 className="sr-only">PanCode</h1>
        </Flex>
      </Box>

      <Flex
        zIndex={"1"}
        position="absolute"
        justifyContent={"center"}
        bottom={"100px"}
        animation=" 4s ease-in fade"
        transition={"all 0.5s"}
        transform={count === 0 ? "translateY(0px)" : "translateY(300px)"}
      >
        <Box w={["80%", "70%", "65%", "65%", "62%"]}>
          <Text
            m="0"
            textAlign={"center"}
            fontWeight={"400"}
            fontFamily={"Extended Semi Bold Neue Plak"}
            opacity={"1"}
            fontSize={["15px", "15px", "15px", "18px", "18px"]}
            color={"white"}
          >
            We offer an assortment of quality services to build and preserve in
            the global technology.
          </Text>
          <Flex
            gap={"10px"}
            justifyContent={"center"}
            alignItems={"center"}
            mt="20px"
          >
            <Button
              onClick={() => navigate("/contact-us")}
              cursor={"pointer"}
              transition={"all 0.5s"}
              _hover={{ bg: "transparent", color: "#49C2C7" }}
              p="10px 32px"
              bg="#49C2C7"
              fontSize={"15px"}
              fontWeight={"bold"}
              fontFamily={"Neue Plak"}
              color="#272727"
              border="1px solid #49C2C7"
              borderRadius={"25px"}
            >
              Contact Us
            </Button>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};
export default WelcomeToPancode;
